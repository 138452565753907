import React, { Component } from "react";
import { livingAction } from "../../actions";
import { connect } from "react-redux";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import SaveHomeCard from "../../components/cards/SaveRecent";
class RecentlyViewed extends Component {
  static contextType = SpinnerContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { recentlyViewed, favilityAll } = this.props;
    const recentArray = recentlyViewed ?? [];
    const favilityArray = favilityAll?.data ?? [];

    const totalLength = [...recentArray, ...favilityArray];
    const proSubscriptions = totalLength?.filter(
      (obj) => obj?.isSubscribed === true || obj?.isClaimRequest === true
    );

    const proSubscriptionsLength = proSubscriptions?.length;

    return (
      <>
        <div className="savehome-wrp setting-list recommended active" id="two">
          <h3 className="main-title mb-0 flex-fill">
            Recently Viewed ({parseInt(proSubscriptionsLength)})
          </h3>
          <SaveHomeCard data={recentlyViewed} name={"Sober Living"} />

          <SaveHomeCard
            data={favilityAll?.data?.filter(
              (item) => item.facilityType === "iop"
            )}
            name={"Iop"}
          />

          <SaveHomeCard
            data={favilityAll?.data?.filter(
              (item) => item.facilityType === "php"
            )}
            name={"Php"}
          />

          <SaveHomeCard
            data={favilityAll?.data?.filter(
              (item) => item.facilityType === "residential"
            )}
            name={"Residential"}
          />
          <SaveHomeCard
            data={favilityAll?.data?.filter(
              (item) => item.facilityType === "detox"
            )}
            name={"Detox"}
          />
        </div>
      </>
    );
  }
}

export default connect(
  ({ living }) => ({
    isFetchingRecentlyViewed: living.fetchingRecentlyViewed,
    recentlyViewed: living.recentlyViewed,
    favilityAll: living.recentlyViewedData,
  }),
  livingAction
)(RecentlyViewed);
