import React, { Component } from "react";
import Slider from "react-slick";

export const SliderContext = React.createContext([]);

export class SliderComponent extends Component {
  static contextType = SliderContext;
  settings = {
    dots: false,
    infinite: false,
    speed: 300,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 987,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  render() {
    const CardComponent = this?.props?.component;
    const settings = {
      ...this.settings,
      ...this.props.settings,
    };
    const ids = this?.props?.items?.map((item) => item?.id);
    return (
      <Slider {...settings}>
        {this?.props?.items?.length > 0 &&
          this?.props?.items?.map((item, index) => {
            return (
              <div key={index}>
                <CardComponent
                  item={item}
                  onDeleteLiving={() => {
                    // Use filter to get the id of the current item
                    const currentItemId = ids?.filter(
                      (id) => id === item?.id
                    )[0];
                    this.props.onDeleteLiving(currentItemId);
                  }}
                  showActionItems={this.props.showActionItems}
                  isRecommended={false}
                  ratingOutside={item?.isSubscribed === true ? true : false}
                  showRating={item?.isSubscribed === true ? "Yes" : "No"}
                  hideAmount={this?.props?.hideAmount}
                  isHost={this?.props?.isHost}
                />
              </div>
            );
          })}
      </Slider>
    );
  }
}
