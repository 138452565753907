import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import AddDetox from "./add-detox";
import DetoxDetails from "./view-detoxDetails";

class Detox extends Component {
  render() {
    const AdminSecuredRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          // ternary condition

          this.props.isAuthorized && this.props.isHost ? (
            <Component {...props} />
          ) : (
            <Redirect to="/?isSignIn=true" />
          )
        }
      />
    );

    return (
      <>
        <ReactHelmet
          title={"Taste Recovery"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />

        <Switch>
          <AdminSecuredRoute
            path="/detoxfacility/add-detox/:currentStep?"
            exact={true}
            component={AddDetox}
          />
          <AdminSecuredRoute
            path="/detoxfacility/edit/:id/:currentStep?"
            exact={true}
            component={AddDetox}
          />
           <AdminSecuredRoute
            path="/detoxfacility/view/:id"
            exact={true}
            component={DetoxDetails}
          />
        </Switch>
      </>
    );
  }
}

export default connect(({ auth }) => ({
  isAuthorized: auth?.authToken != null && auth.user != null,
  isHost: auth?.isHost,
}))(Detox);
