import React, { Component } from "react";
import { connect } from "react-redux";
import { livingAction } from "../../actions";
import deleteImg from "../../assets/images/ic_close.svg";
import {
  StandartOptions,
  RoomOptions,
  PropertyServing,
  PaymentPeriodFilter,
  sobrietyRequirement,
  OccupancyMeter,
  seoUrlText,
} from "../../constants";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class SavedAlerts extends Component {
  static contextType = SpinnerContext;
  defaultParams = {
    paymentPeriod: PaymentPeriodFilter.Any,
    servingForWhom: PropertyServing.Any,
    availability: StandartOptions.Any,
    pets: StandartOptions.Any,
    transportation: StandartOptions.Any,
    room: RoomOptions.Any,
    bathroom: RoomOptions.Any,
    handicapFriendly: StandartOptions.Any,
    smokeFriendly: StandartOptions.Any,
    MAT_accepted_house: StandartOptions.Any,
    availability: StandartOptions.Any,
    privateChef: StandartOptions.Any,
    priceMin: parseInt(process.env.REACT_APP_MIN_PRICE_RANGE),
    priceMax: parseInt(process.env.REACT_APP_MAX_PRICE_RANGE),
    sobrietyRequirement: sobrietyRequirement[0].value,
    maximumOccupancy: OccupancyMeter["ONE"],
    children: StandartOptions.Any,
  };
  componentDidMount() {
    this.context.showLoader("Fetching saved alerts");
    this.props.fetchAlerts().finally(() => this.context.hideLoader());
  }
  getCoordinates = (stateLong) => {
    const matchingState = this.props.states.find(
      (state) => state.stateLong.toLowerCase() === stateLong.toLowerCase()
    );

    return matchingState?.coordinates || { lat: 0, lng: 0 }; // Default coordinates if not found
  };
  onAlertClick = (alert) => {
    // 'this' is bound correctly
    const {
      stateLong,
      paymentPeriod,
      servingForWhom,
      availability,
      pets,
      transportation,
      room,
      bathroom,
      handicapFriendly,
      smokeFriendly,
      privateChef,
      children,
      acceptMAT,
      isCertifiedSoberLiving,
      priceMin,
      priceMax,
      coordinates,
      sobrietyRequirement,
      maximumOccupancy,
      totalEntries,
    } = alert;
    const searchParams = {
      paymentPeriod: paymentPeriod || "any",
      servingForWhom: servingForWhom || "any",
      availability: availability || "any",
      pets: pets || "any",
      transportation: transportation || "any",
      room: room || "any",
      bathroom: bathroom || "any",
      handicapFriendly: handicapFriendly || "any",
      smokeFriendly: smokeFriendly || "any",
      privateChef: privateChef || "any",
      children: children || "any",
      acceptMAT: acceptMAT || "any",
      isCertifiedSoberLiving: isCertifiedSoberLiving || "any",
      priceMin: priceMin || 0,
      priceMax: priceMax || 30000,
      page: 1,
      stateLong,
      coordinates: {},
      sobrietyRequirement: sobrietyRequirement || "",
      maximumOccupancy: maximumOccupancy || "",
    };
    // Find matching state
    const matchingState = this.props.states.find(
      (state) => state.stateLong.toLowerCase() === stateLong.toLowerCase()
    );

    const lat = matchingState?.coordinates?.lat;
    const lng = matchingState?.coordinates?.lng;
    if (searchParams.maximumOccupancy === "") {
      delete searchParams.maximumOccupancy;
    }
    if (searchParams.sobrietyRequirement === "") {
      delete searchParams.sobrietyRequirement;
    }

    this.props.history.push(
      `../living/search/${seoUrlText}-${stateLong.toLowerCase()}`,
      { lat, lng, searchParams: searchParams, count: totalEntries }
    );
  };
  render() {
    return (
      <div className="alert-wrp setting-list">
        <h3 className="main-title">Saved Alerts</h3>
        <div className="saved-alerts-wrappper mt-4">
          {this.props.alerts.map((alert, index) => {
            return (
              <div
                className="alert-wrapper"
                key={index}
                onClick={() => this.onAlertClick(alert)}
              >
                <span className="alert-title">
                  <strong>{alert.totalEntries}</strong> matches out of{" "}
                  {alert.allEntries} properties
                </span>
                <p>
                  <span>More than ${alert.priceMin}</span>
                  <span> • Less than ${alert.priceMax}</span>
                  {alert.paymentPeriod !== this.defaultParams.paymentPeriod && (
                    <span> • Payment Period : {alert.paymentPeriod}</span>
                  )}
                  {alert.servingForWhom !==
                    this.defaultParams.servingForWhom && (
                    <span> • Serving for : {alert.servingForWhom}</span>
                  )}
                  {alert.children !== this.defaultParams.children && (
                    <span> • Allowed With Children : {alert.children}</span>
                  )}
                  {alert.availability !== this.defaultParams.availability && (
                    <span> • Availability : {alert.availability}</span>
                  )}
                  {alert.pets !== this.defaultParams.pets && (
                    <span> • Pets : {alert.pets}</span>
                  )}
                  {alert.transportation !==
                    this.defaultParams.transportation && (
                    <span> • Transportation : {alert.transportation}</span>
                  )}
                  {alert.room !== this.defaultParams.room && (
                    <span> • Room : {alert.room}</span>
                  )}
                  {alert.bathroom !== this.defaultParams.bathroom && (
                    <span> • Bathroom : {alert.bathroom}</span>
                  )}
                  {alert.handicapFriendly !==
                    this.defaultParams.handicapFriendly && (
                    <span> • Handicap Friendly : {alert.handicapFriendly}</span>
                  )}
                  {alert.smokeFriendly !== this.defaultParams.smokeFriendly && (
                    <span> • Smoke Friendly : {alert.smokeFriendly}</span>
                  )}

                  {alert.acceptMAT !==
                    this.defaultParams.MAT_accepted_house && (
                    <span> • MAT accepted house : {alert.acceptMAT}</span>
                  )}

                  {alert.privateChef !== this.defaultParams.privateChef && (
                    <span> • Private Chef : {alert.privateChef}</span>
                  )}
                  {alert.sobrietyRequirement &&
                    alert.sobrietyRequirement !==
                      this.defaultParams.sobrietyRequirement && (
                      <span>
                        {" "}
                        • Sobriety Requirement :{" "}
                        {this.getSoberityLable(alert.sobrietyRequirement)}
                      </span>
                    )}
                  {alert.maximumOccupancy &&
                    alert.maximumOccupancy !==
                      this.defaultParams.maximumOccupancy && (
                      <span>
                        {" "}
                        • Maximum Occupancy : {alert.maximumOccupancy}
                      </span>
                    )}
                </p>
                <span
                  className="delete-alert"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.reomveAlert(alert.id);
                  }}
                >
                  <img src={deleteImg} alt="" />
                </span>
              </div>
              // </Link>
            );
          })}
          {this.props.alerts.length === 0 && (
            <div className="no-records">
              <h2 className="text-uppercase">
                There are no saved alerts at this time
              </h2>
              <p>
                Creating an alert allows you to follow your search criteria so
                you never miss out on a great sober living!
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  reomveAlert = (alertId) => {
    this.props
      .deleteAlert(alertId)
      .then(() => this.context.showMessage("Removed alert successfully!!!"));
  };

  getSoberityLable = (value) => {
    const index = sobrietyRequirement.findIndex((i) => i.value === value);
    return sobrietyRequirement[index].label;
  };
}

export default connect(
  ({ living, home }) => ({
    alerts: living.alerts,
    states: home.states,
    coordinates: home.coordinate,
  }),
  livingAction
)(SavedAlerts);
