import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import AddPhp from "./add-php";
import PhpDetails from "./view.phpDetail";

class Php extends Component {
  render() {
    const AdminSecuredRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.props.isAuthorized && this.props.isHost ? (
            <Component {...props} />
          ) : (
            <Redirect to="/?isSignIn=true" />
          )
        }
      />
    );

    return (
      <>
        <ReactHelmet
          title={"Taste Recovery"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />

        <Switch>
          <AdminSecuredRoute
            path="/phpfacility/add-php/:currentStep?"
            exact={true}
            component={AddPhp}
          />
          <AdminSecuredRoute
            path="/phpfacility/edit/:id/:currentStep?"
            exact={true}
            component={AddPhp}
          />
          <AdminSecuredRoute
            path="/phpfacility/view/:id"
            exact={true}
            component={PhpDetails}
          />
        </Switch>
      </>
    );
  }
}

export default connect(({ auth }) => ({
  isAuthorized: auth?.authToken != null && auth.user != null,
  isHost: auth?.isHost,
}))(Php);
