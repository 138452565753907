import React, { Component } from "react";
import ReactHelmet from "../../components/react-helmet/react-helmet"

import { connect } from "react-redux";

class FAQ extends Component {
  render() {
    return (
      <>
      <ReactHelmet title={"Taste Recovery - Faqs"} description={"Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."}/>
      <section
        className={`container savehome-wrp setting-list ${
          this.props.location.pathname === "/settings/faq" ? "p-0" : ""
        }`}
      >
        <h3 className="main-title text-capitalize">{this.props.isHost ?"FAQ":"FAQs"}</h3>
        <div className="saved-home-wrappper mt-4">
          <div className="cms-wrp">
            <span className="heading">
              1. Will I be charged a fee for moving into a home that I found
              through the app?{" "}
            </span>
            <p>
              Taste Recovery does not charge the user a fee for using the app,
              nor do we make commission when someone moves into a sober living
              home from our app.{" "}
            </p>
            <span className="heading">
              2. What happens if the sober living I move into is not the right
              fit?
            </span>
            <p>
              Each sober living has different contracts regarding if and when
              they refund someone once they have moved in, which needs to be
              discussed further with the owner of that sober living. We are a
              third party and are not responsible for the sober living homes.
              However, we would greatly appreciate a review of the home once you
              have moved in.{" "}
            </p>
            <span className="heading">
              3. I’ve never been to a sober living, how do I know what questions
              to ask to make sure it is the right place for me?
            </span>
            <p>
              This is a personal decision and will be different for everyone,
              but focus on what truly matters to you. If you are looking for a
              house with women your age, you can ask the owner or house manager
              what the age range is in the house through our messaging system.
              If you are nervous about too many women sharing a bathroom, you
              can ask how many women share each bathroom in the house. If you
              have to wake up early every morning for work, you can ask what the
              quiet hours are and are they actually implementing this rule? If
              you are looking for a very structured home to keep you
              accountable, you can ask how many meetings are required each week
              and how does the house manager check? Does the sober living have
              you sign a meeting card? There are so many important questions to
              ask, but it truly depends on what the individual feels is
              important to their sobriety and serenity. This is why we have set
              up the messaging system, so you can have all of your questions
              answered and you can feel sure about this next move.{" "}
            </p>
            <span className="heading">
              4. Can I schedule a tour with a sober living home prior to moving
              in?{" "}
            </span>
            <p>
              Absolutely! We have made this step easy through our app so that
              you can tour as many sober homes as possible. This is a big
              decision and we encourage tours if you are in the area and can do
              so!{" "}
            </p>
            <span className="heading">
              5. How can I add my sober living to your website?
            </span>
            <p>
              Create an account on the app and state that you own a sober
              living. You can add your sober living homes information through
              the profile icon, including pictures, house rules, and so much
              more.
            </p>
            <span className="heading">
              6. My sober living is not well known and would love to be a
              promoted house on your app, how does this work?
            </span>
            <p>
              Once you have created a profile and uploaded your sober living,
              you can then upgrade your house to a promoted home through our ad
              services.{" "}
            </p>
          </div>
        </div>
      </section>
      </>
    );
  }
}
export default connect(
  ({ auth }) => ({
    isHost: auth?.isHost,
  })
)(FAQ);
// export default FAQ;
