import React, { Component } from "react";
import * as auth from "../../actions/index";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";

class RemovedAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }
  componentDidMount() {
    //this.props.logout();
  }

  handleClose = () => {
    this.setState({ showModal: true });
    this.props.history.goBack();
  };

  handeYes = () => {
    this.setState({ showModal: true });
    this.props.removeUser();
  };

  render() {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove account</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`text-center${this.props?.role ? "-left" : ""}`}>
          {this.props?.role
            ? "Are you sure you want to continue?"
            : "Are you sure you want to delete account?"}{" "}
          <br />
          {this.props?.role ? (
            <>
              Removing your account will automatically unsubscribe you from all
              facilities and sober homes associated with your account, and you
              will no longer be able to access the account.
              <br />
            </>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={this.handeYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  ({ auth }) => ({
    hasAuthToken: Boolean(auth.authToken),
    role: auth?.user?.role === "host" ? true : false,
  }),
  auth.authAction
)(RemovedAccount);
