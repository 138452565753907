import React, { useMemo, useState, useContext } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { paymentActions } from "./../../actions";
import ToastError from "../../components/error/toast-error";
import { SpinnerContext } from "../../components/spinner/spinner.component";

const useOptions = () => {
  const fontSize = "16px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const AddCardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const context = useContext(SpinnerContext);

  const [cardName, setCardName] = useState("");
  const [showError, setShowError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    context.showLoader("Saving the card");
    if (!stripe || !elements) {

      return;
    }

    props.initCard().then(async (clientSecret) => {
      setShowError(false);
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: cardName,
          },
        },
      });

      if (result.error) {
        setShowError(true)
        props.onAddingCardFailed(result.error);
        context.hideLoader()
      } else {
        const {
          setupIntent: { payment_method },
        } = result;
        props
          .addCard({
            paymentMethodId: payment_method,
            cardName,
          })
          .then((card) => {
            context.showMessage("Added card successfully!");
            props.onHide();
          })
          .catch(() => {
            setShowError(true);
          })
          .finally(() => context.hideLoader());
      }
    });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="text-center"> Add Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <ToastError
          delay={10000}
          error={props.error}
          showError={showError}
          hideError={() => setShowError(false)}
        />
        <form onSubmit={handleSubmit} className="card-form1">
          <div className="form-group">
            <CardElement
              options={options}
              onReady={() => {
              }}
              onChange={(event) => {
                setIsValid(event.error === undefined);
              }}
              onBlur={() => {
              }}
              onFocus={() => {
              }}
            />
          </div>

          <div className="form-group">
            <input
              name="name"
              className="form-control"
              type="text"
              placeholder="Jane Doe"
              onChange={(e) => setCardName(e.target.value)}
              required
            />
          </div>

          <Button type="submit" disabled={stripe === null || !isValid}>
            Add
          </Button>
        </form>
      </Modal.Body>
    </>
  );
};

export default connect(
  ({ payment }) => ({
    isAddingPayment: payment.isAddingPayment,
    error: payment.error,
  }),
  paymentActions
)(AddCardForm);
