import React, { useEffect } from "react";

const FAQSchema = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What is PHP and how does it differ from inpatient and outpatient treatment?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "PHP is an acronym that stands for “Partial Hospitalization Program.” It is designed as a mid-range option of treatment. Specifically, it is designed to help with certain mental conditions, substance abuse, and addiction. Think of a PHP program as more involved than an outpatient center but less so than a 24-hour supervised care program. PHP treatment programs administered through a licensed treatment facility are ideal for many patients. Specifically, those with average substance or behavioral health needs. Treatment options are tailored to their circumstances, with higher levels of care administered when deemed necessary.",
          },
        },
        {
          "@type": "Question",
          name: "Who works with patients a PHP rehab program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Each location is served by licensed medical, behavioral health, and substance abuse professionals. Each patient gets access to best in class care delivered by staff that ensures the care they need.",
          },
        },
        {
          "@type": "Question",
          name: "What kinds of treatments and therapies are used in PHP rehab?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "PHP treatment can vary depending on the location and the staff that supports it. However, many centers are equipped to provide comprehensive PHP treatments and PHP therapy to incoming patients.",
          },
        },
        {
          "@type": "Question",
          name: "How is PHP therapy treatment administered?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "While group therapies are conducted and administered to a group, this is not true for all. Many of the PHP treatments offered are individualized per patient. This means specialized care in the areas a patient most needs support, which can lead to better and more lasting outcomes.",
          },
        },
        {
          "@type": "Question",
          name: "Can I really see an improvement from PHP on mental health?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The goal of each PHP drug treatment and mental health support centers is patient success. We strive to provide a foundation for healthy decisions, improvement-centric thinking, and better outcomes. Many of our patients are pleased with the process. They find the support they receive from PHP mental health and substance interventions support their goals.",
          },
        },
        {
          "@type": "Question",
          name: "How can I learn more about a particular PHP rehab center?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We recommend browsing the list of partial hospitalization programs above. There’s a good chance there is a PHP treatment center located close to you. Once you have identified a PHP program that works for you, you can email or call the admissions team directly. They will assist you in setting up a tour.",
          },
        },
      ],
    });
    document.head.appendChild(script);

    // Cleanup the script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; // No UI is rendered for this component
};

export default FAQSchema;
