import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import { homeAction } from "../../actions";
import { residentialFilter } from "./facilityCenters/filterOptions";
import FacilityHeader from "../../components/home/Header.component";
import FilterSection from "../../components/home/FilterSection.component";
import { FilteredOptions } from "./facilityCenters/filters";
import FilterModel from "../../components/home/filter.component";
import FacilityList from "../../components/home/facilityList.component";
import Logoutsessionmodal from "../../components/modal/Logoutsessionmodal";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import {
  checkHasNavigationPermission,
  getGeoLocation,
  getCoordinates,
} from "../../utils";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Residetialheader from "../../components/content/Residetialheader";
import Residentialcontent from "../../components/content/Residentialcontent";
const ResidentialFacility = (props) => {
  const locations = useLocation();
  const { getActiveFacility } = props;
  const state = useSelector((state) => state);

  const allFacilityList = useSelector((state) => state?.home?.allFacilityList);
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [getData, setGetData] = useState(null);
  const [activeConditionTreated, setActiveConditionTreated] = useState([]);
  const [activeFacility, setActiveFacility] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchInputtext, setSearchInputtext] = useState("");
  const [selectSearchOption, setSelectSearchOption] = useState({
    label: "Search by facility location",
    value: "location",
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [recordsPerPage, setRecordsperPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchCords, setSearchCords] = useState({});
  const [isSearchModelOpen, setIsSearchModelOpen] = useState(true);
  const [specialized, setSpecialized] = useState([]);
  const [conditionTreated, setConditionTreated] = useState([]);
  const [medicationTherapy, setMedicationTherapy] = useState([]);
  const [treatmentApproaches, setTreatmentApproaches] = useState([]);
  const [preTreatment, setPreTreatment] = useState([]);
  const [testing, setTesting] = useState([]);
  const [smokingPolicy, setSmokingPolicy] = useState([]);
  const [isGenderSpecific, setIsGenderSpecific] = useState(false);
  const [insurance, setInsurance] = useState("");
  const [gender, setGender] = useState("");
  const [verifiedBadge, setIsVerifiedBadge] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [finalFilter, setFinalFilter] = useState(residentialFilter);
  const [isScholarshipOffer, setIsScholarshipOffer] = useState(false);
  const [facilityCount, setFacilityCount] = useState("");
  const [allowableStay, setAllowableStay] = useState({ from: "", to: "" });
  const [stateName, setStateName] = useState("");
  const [loading, setLoading] = useState(true);
  const stateValues = {
    specialized,
    conditionTreated,
    medicationTherapy,
    treatmentApproaches,
    preTreatment,
    testing,
    smokingPolicy,
    isGenderSpecific,
    gender,
    verifiedBadge,
    isFeatured,
    finalFilter,
    facilityCount,
    allowableStay,
    isScholarshipOffer,
  };

  const setValues = {
    setSpecialized,
    setConditionTreated,
    setMedicationTherapy,
    setPreTreatment,
    setTreatmentApproaches,
    setTesting,
    setIsGenderSpecific,
    setGender,
    setSmokingPolicy,
    setIsFeatured,
    setIsVerifiedBadge,
    setAllowableStay,
    setIsScholarshipOffer,
    setSelectedItems,
    setInsurance,
  };

  const isMobile = () => window.innerWidth <= 575;

  useEffect(() => {
    dispatch(
      homeAction.fetchFacilityListwithoutCords({
        facilityType: "residential",
        perPage: 9,
      })
    );
  }, []);

  useEffect(() => {
    let isMounted = true;

    const firstTimeLoad = async () => {
      try {
        const granted = await checkHasNavigationPermission();
        if (isMounted) {
          if (!granted) {
            setLoading(true); // Set loading state true when data is fetching
            showLoader(
              `Finding the best ${
                activeFacility === "sober"
                  ? "sober livings"
                  : "facility centers"
              } near you`
            );
            await dispatch(
              homeAction.fetchFacilityList({
                facilityType: "residential",
                perPage: 9,
              })
            );
            hideLoader("");
            setLoading(false); // Set loading state to false when fetching is done
          }

          // Get geolocation and fetch data with coordinates
          const position = await getGeoLocation();
          const { latitude, longitude } = position.coords;
          const data = {
            coordinates: {
              lat: Number(latitude),
              lng: longitude,
            },
          };
          localStorage.setItem("coordinates", JSON.stringify(data));

          setLoading(true); // Set loading when fetching based on geolocation
          showLoader(
            `Finding the best ${
              activeFacility === "sober" ? "sober livings" : "facility centers"
            } near you`
          );
          await dispatch(
            homeAction.fetchFacilityList({
              facilityType: "residential",
              perPage: 9,
              coordinates: data.coordinates,
            })
          );
          hideLoader("");
          setLoading(false); // Set loading state to false after data is loaded
        }
      } catch (error) {
        if (isMounted) {
          await dispatch(
            homeAction.fetchFacilityList({
              facilityType: "residential",
              perPage: 9,
            })
          );
          setLoading(false); // Ensure to hide loader even on error
        }
      }
    };

    firstTimeLoad();

    return () => {
      isMounted = false;
    };
  }, [activeFacility, dispatch]);

  useEffect(() => {
    if (locations.pathname === "/") {
      setActiveFacility("residential");
    } else {
      setActiveFacility(locations?.pathname?.replace("/", ""));
    }
  }, [locations.pathname]);

  const handleCheckboxChange = (option, checkbox) => {
    if (checkbox === "specialized") {
      if (specialized.includes(option)) {
        setSpecialized(specialized.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setSpecialized([...specialized, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "conditionTreated") {
      if (conditionTreated.includes(option)) {
        setConditionTreated(
          conditionTreated?.filter((value) => value !== option)
        );
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setConditionTreated([...conditionTreated, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "medicationTherapy") {
      if (medicationTherapy.includes(option)) {
        setMedicationTherapy(
          medicationTherapy.filter((value) => value !== option)
        );
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setMedicationTherapy([...medicationTherapy, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "treatmentApproaches") {
      if (treatmentApproaches.includes(option)) {
        setTreatmentApproaches(
          treatmentApproaches.filter((value) => value !== option)
        );
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setTreatmentApproaches([...treatmentApproaches, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "preTreatment") {
      if (preTreatment.includes(option)) {
        setPreTreatment(preTreatment.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setPreTreatment([...preTreatment, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "testing") {
      if (testing.includes(option)) {
        setTesting(testing.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setTesting([...testing, option]);
        setSelectedItems([...selectedItems, option]);
      }
    } else if (checkbox === "smokingPolicy") {
      if (smokingPolicy.includes(option)) {
        setSmokingPolicy(smokingPolicy.filter((value) => value !== option));
        setSelectedItems(selectedItems.filter((value) => value !== option));
      } else {
        setSmokingPolicy([...smokingPolicy, option]);
        setSelectedItems([...selectedItems, option]);
      }
    }
  };

  const handleOnChange = (key, value) => {
    switch (key) {
      case "isGenderSpecific":
        setIsGenderSpecific(value);
        const index = selectedItems.indexOf("genderSpecific");
        if (index === -1) {
          setSelectedItems((prevState) => [...prevState, "genderSpecific"]);
        } else {
          const updatedItems = selectedItems.filter(
            (item) => item !== "genderSpecific"
          );
          setSelectedItems(updatedItems);
        }
        break;
      case "gender":
        setGender(value);
        break;
      case "from":
        setAllowableStay({ ...allowableStay, from: value });
        break;
      case "to":
        setAllowableStay({ ...allowableStay, to: value });
        break;
      case "verifiedBadge":
        setIsVerifiedBadge(value);
        break;
      case "isFeatured":
        setIsFeatured(value);
        break;
      case "isScholarshipOffer":
        setIsScholarshipOffer(value);
        break;
      default:
        break;
    }
  };

  const getPostData = (data) => {
    setGetData(data);
  };

  const handleConditionTreated = (key, value) => {
    const index = selectedItems.indexOf(value);
    if (index === -1) {
      setSelectedItems((prevState) => [...prevState, value]);
      switch (key) {
        case "specialized":
          setSpecialized((prevState) => [...prevState, value]);
          break;
        case "conditionTreated":
          setConditionTreated((prevState) => [...prevState, value]);
          break;
        case "isGenderSpecific":
          setIsGenderSpecific(!isGenderSpecific);
          break;
        case "treatmentApproaches":
          setTreatmentApproaches((prevState) => [...prevState, value]);
          break;
        case "insurance":
          setInsurance((prevState) => [...prevState, value]);
        default:
          break;
      }
    } else {
      const updatedItems = selectedItems.filter((item) => item !== value);
      setSelectedItems(updatedItems);
      switch (key) {
        case "specialized":
          setSpecialized((prevState) =>
            prevState.filter((item) => item !== value)
          );
          break;
        case "conditionTreated":
          setConditionTreated((prevState) =>
            prevState.filter((item) => item !== value)
          );
          break;
        case "isGenderSpecific":
          setIsGenderSpecific(!isGenderSpecific);
          break;
        case "treatmentApproaches":
          setTreatmentApproaches((prevState) =>
            prevState.filter((item) => item !== value)
          );
          break;

        case "insurance":
          setInsurance((prevState) =>
            prevState.filter((item) => item !== value)
          );
          break;
        default:
          break;
      }
    }
  };

  const data = {
    facilityType: activeFacility,
    conditionTreated: conditionTreated,
    searchBar: Object.keys(searchCords).length > 0 ? stateName : searchInput,
    specialized: specialized,
    medicationTherapy: medicationTherapy,
    genderSpecific: isGenderSpecific,
    gender: gender,
    treatmentApproaches: treatmentApproaches,
    preTreatment: preTreatment,
    testing: testing,
    smokingPolicy: smokingPolicy,
    verifiedBadge: verifiedBadge,
    featured: isFeatured,
    perPage: recordsPerPage,
    allowableStay:
      allowableStay.from === "" && allowableStay.to === ""
        ? null
        : allowableStay,
    insurance: insurance,
    offersScholarships: isScholarshipOffer,
    // ...(Object.keys(searchCords).length > 0 && {coordinates: searchCords }),
    ...(Object.keys(searchCords).length > 0
      ? { coordinates: searchCords }
      : { coordinates: getCoordinates()?.coordinates }),
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInputtext(val);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchInput(searchInputtext);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchInputtext]);

  const fetchData = async () => {
    try {
      !openFilterModal &&
        !isSearchModelOpen &&
        showLoader(
          `Finding the best ${
            activeFacility === "sober" ? "sober livings" : "facility centers"
          } near you`
        );

      const res = await dispatch(homeAction.fetchFacilityList(data));
      hideLoader("");
    } catch (error) {
      console.error("Error fetching facility list:", error);
      hideLoader("");
    }
  };
  useEffect(() => {
    if (!isSearchModelOpen) {
      fetchData();
    }
  }, [
    isSearchModelOpen,
    searchCords,
    activeFacility,
    specialized,
    getData,
    recordsPerPage,
    gender,
    isGenderSpecific,
    isFeatured,
    verifiedBadge,
    conditionTreated,
    allowableStay,
    medicationTherapy,
    searchInput,
    treatmentApproaches,
    preTreatment,
    testing,
    smokingPolicy,
    insurance,
    isScholarshipOffer,
  ]);
  const getRecordsPerPage = (data) => {
    setRecordsperPage(data);
  };

  const getsCords = (data) => {
    setSearchCords(data);
  };
  const getsModelStatus = (data) => {
    setIsSearchModelOpen(data);
  };

  useEffect(() => {
    if (activeFacility === "sober") {
      getActiveFacility(true);
    }
  }, [activeFacility]);

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What are residential treatment centers?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Residential treatment centers are facilities that provide structured and supportive environments for individuals struggling with mental health issues, substance use disorders, eating disorders, etc. These centers offer 24/7 care and support, helping individuals focus on their recovery in a safe and therapeutic setting.",
        },
      },
      {
        "@type": "Question",
        name: "What’s the difference between residential drug abuse treatment programs and those for mental health or other behavioral disorders?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Residential programs for drug abuse focus on helping individuals overcome substance use disorders. These programs often start with detox, followed by therapy and support to address addiction and any co-occurring mental health issues.",
        },
      },
      {
        "@type": "Question",
        name: "What is detox?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Detox is the process of safely removing harmful substances from the body under medical supervision. This is often the first step in residential drug treatment programs. During detox, medical professionals manage withdrawal symptoms and ensure the individual's safety and comfort.",
        },
      },
      {
        "@type": "Question",
        name: "Do residential substance abuse treatment programs always include detox?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "No, residential drug treatment programs do not always include detox. Detox is only necessary if the individual needs to safely withdraw from substances. Some people may have already completed detox before entering a residential treatment program.",
        },
      },
      {
        "@type": "Question",
        name: "What is a Partial Hospitalization Program (PHP)?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "A Partial Hospitalization Program (PHP) provides intensive care during the day but allows individuals to return home at night. PHPs offer therapy, medical care, and support for those who need more help than outpatient treatment but do not need 24/7 residential care.",
        },
      },
      {
        "@type": "Question",
        name: "What is an Intensive Outpatient Program (IOP)?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "An Intensive Outpatient Program (IOP) offers intensive therapy and support while allowing individuals to continue living at home. IOPs include individual therapy, group counseling, and educational sessions to help individuals manage their recovery while maintaining their daily responsibilities. Generally, people in this program will meet with professionals a few times a week.",
        },
      },
      {
        "@type": "Question",
        name: "How is PHP and IOP different?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "PHPs provide more intensive care and support, typically involving daily treatment for several hours each day. Individuals in PHPs go home at night but spend every day at the treatment facility. IOPs, on the other hand, require fewer hours each week. IOPs allow individuals to maintain more of their regular daily activities while still receiving treatment.",
        },
      },
      {
        "@type": "Question",
        name: "What is sober living?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Sober living refers to group homes or residences where individuals in recovery live together in a supportive environment. These homes provide a safe and structured environment for people who have completed residential treatment programs but still need support. They support people as they transition back to everyday life. During their stay, residents follow house rules, participate in chores, and often attend therapy or support group meetings.",
        },
      },
    ],
  };
  return (
    <>
      <ReactHelmet
        title={
          "Taste Recovery - Discover Mental Health and Addiction Treatment Search Service"
        }
        description={
          "Discover the residential treatment center that is best for you or your loved one with the help of Taste Recovery."
        }
        faqSchema={faqSchema}
      />
      <FacilityHeader
        activeFacility={activeFacility}
        setActiveFacility={setActiveFacility}
        setSearchInput={setSearchInput}
        setStateName={setStateName}
        searchInputtext={searchInputtext}
        setSearchInputtext={setSearchInputtext}
        selectSearchOption={selectSearchOption}
        setSelectSearchOption={setSelectSearchOption}
        setOpenFilterModal={setOpenFilterModal}
        getsCords={getsCords}
        getsModelStatus={getsModelStatus}
        perPage={state?.home?.facilityList?.total}
        setIsSearchModelOpen={setIsSearchModelOpen}
        allFacilityList={allFacilityList}
      />
      <section className="facility-list-wrapper">
        <FilterSection
          FilteredOptions={FilteredOptions}
          selectedItems={selectedItems}
          handleConditionTreated={handleConditionTreated}
          isMobile={isMobile}
          setOpenFilterModal={setOpenFilterModal}
        />

        <FacilityList
          searchInput={searchInput}
          recordsPerPage={recordsPerPage}
          getRecordsPerPage={getRecordsPerPage}
          currentPage={currentPage}
          activeFacility={activeFacility}
        />
        <Residetialheader />
        <Residentialcontent />
      </section>
      {openFilterModal && (
        <FilterModel
          openFilterModal={openFilterModal}
          setOpenFilterModal={setOpenFilterModal}
          selectedItems={selectedItems}
          handleConditionTreated={handleConditionTreated}
          stateValues={stateValues}
          facilityType={activeFacility}
          searchBar={searchInput}
          getPostData={getPostData}
          conditionTreated={activeConditionTreated}
          handleCheckboxChange={handleCheckboxChange}
          handleOnChange={handleOnChange}
          setValues={setValues}
          total={state?.home?.facilityList?.total}
          isScholarshipOffer={isScholarshipOffer}
          open={openFilterModal}
          onCancel={() => setOpenFilterModal(false)}
        />
      )}
      <Logoutsessionmodal />
    </>
  );
};

export default ResidentialFacility;
