import React from "react";

const CondTreatedFilter = (props) => {
  const { ele, selectedItems, handleClick } = props;
  return (
    <div
      className={
        selectedItems.length > 0 && selectedItems?.includes(ele.value)
          ? "tab-item active"
          : "tab-item"
      }
      onClick={() => handleClick()}
    >
      <a href="#" className="tab-link">
        <img src={ele.img} className="img-fluid  gray-img" alt={ele.altTag} />
        <img src={ele.activeImg} className="img-fluid active-img" alt="" />
        <span>{ele.label}</span>
      </a>
    </div>
  );
};

export default CondTreatedFilter;
