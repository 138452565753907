import React from "react";
import { useSelector } from "react-redux";
import SaveHomeCard from "../../components/cards/SaveHome";

const SavedHomes = () => {
  const { favLivings, favilityAll } = useSelector((state) => ({
    favLivings: state.living.favLivings,
    favilityAll: state.living.favilityAll,
  }));
  const favList = favLivings ?? [];
  const favilityArray = favilityAll?.data ?? [];
  
  // Merge the arrays
  const totalLength = [...favList, ...favilityArray]

      const proSubscriptions = totalLength?.filter(
        (obj) => obj?.isSubscribed === true ||  obj?.isClaimRequest === true
      );
      
      // // Get the length of the filtered array
  const proSubscriptionsLength = proSubscriptions?.length;
  return (
    <>
      <div className="savehome-wrp setting-list recommended active" id="two">
        <h3 className="main-title mb-0 flex-fill">
          Saved facilities ({proSubscriptionsLength}){" "}
        </h3>
        <SaveHomeCard data={favLivings} name={"Sober Living"} />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "iop"
          )}
          name={"Iop"}
        />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "php"
          )}
          name={"Php"}
        />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "residential"
          )}
          name={"Residential"}
        />
        <SaveHomeCard
          data={favilityAll?.data?.filter(
            (item) => item.facilityType === "detox"
          )}
          name={"Detox"}
        />
      </div>
    </>
  );
};

export default SavedHomes;
