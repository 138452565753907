import React, { Component } from "react";
import { connect } from "react-redux";
import { homeAction, livingAction } from "../../actions";
import { SliderComponent } from "../../components/slider/slider.component";
import StateCard from "../../components/cards/state.card";
import SoberLivingCard from "../../components/cards/sober-living.card";
import { checkHasNavigationPermission, getGeoLocation } from "../../utils";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import LocationSearchInput from "../../components/map/location-search-input.component";
import { seoUrlText } from "../../constants";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import { bindActionCreators } from "redux";
import homeIcon from "../../assets/images/home-new.svg";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FacilityHome from "./facilityCenters/facilityHome.page";
import SoberLivingheader from "../../components/content/soberLivingheader";
import Sobercontent from "../../components/content/Sobercontent";
import Sobercontentfaq from "../../components/content/Sobercontentfaq";
import Soberfaqscript from "../../components/content/Soberfaqscript";
class HomePage extends Component {
  static contextType = SpinnerContext;
  citySliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 987,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1.5,
          infinite: false,
        },
      },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      modelOpen: true,
      activeSober: false,
      activeFacility: "Sober Living",
      facilityCenter: [
        { value: "detox", label: "Detox", link: "detox" },
        { value: "residential", label: "Residential", link: "" },
        { value: "php", label: "PHP", link: "php" },
        { value: "iop", label: "IOP", link: "iop" },
        { value: "sober", label: "Sober Living", link: "soberliving" },
      ],
    };

    this.onCitySelect = this.onCitySelect.bind(this);
    this.getActiveFacility = this.getActiveFacility.bind(this);
  }

  componentDidMount() {
    this.context.setStickyHeader(false);
    const fetchCoordinate = localStorage.getItem("coordinates");
    const isSignIn = new URLSearchParams(this.props.location.search).get(
      "isSignIn"
    );
    if (!isSignIn || this.props.states.length === 0) {
      this.fetchData();
    }
    const closedModal = localStorage.getItem("closedModal");
    if (closedModal) {
      this.setState({ modelOpen: false });
    } else {
      this.setState({ modelOpen: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fetchingData !== this.props.fetchingData) {
      if (this.props.fetchingData)
        this.context.showLoader("Finding the best sober livings near you");
      else this.context.hideLoader();
    }
  }

  handleModelClick = (data, key) => {
    this.props.setModelState(data);
    this.setState({ modelOpen: false });
    localStorage.setItem("closedModal", "true");
    if (key === "yes") {
      localStorage.setItem("isShown", "true");
    } else {
      localStorage.setItem("isShown", "false");
    }
  };

  render() {
    const livingByGender = [];
    if (this.props.livingByGenders) {
      Object.keys(this.props.livingByGenders).forEach((gender) => {
        livingByGender.push(
          <li key={gender}>
            <section className="sobber-living" id="topgenderSection">
              <div className="container">
                {this.props.livingByGenders[gender]?.length > 0 && (
                  <h3 className="main-title">{`Sober Living for ${gender}`}</h3>
                )}
                <div className="slider-wrapper">
                  <SliderComponent
                    component={SoberLivingCard}
                    settings={{ className: "living" }}
                    items={this.props.livingByGenders[gender]}
                    hideAmount="true"
                    isHost={this.props.isHost}
                  />
                </div>
              </div>
            </section>
          </li>
        );
      });
    }

    const recommendeddata = this.props.livingByRecommended;

    return (
      <div>
        <Soberfaqscript />
        <ReactHelmet
          title={
            "Taste Recovery - Discover Mental Health and Addiction Treatment Search Service"
          }
          description={
            "Explore sober living homes near you. Browse our directory of residences providing supportive environments for individuals in addiction recovery, offering structured living arrangements and community support."
          }
        />
        {/* {this.state.activeSober ? ( */}
        <>
          <section className="home-banner">
            <div className="container">
              <div className="banner-content">
                <div className="search-wrapper">
                  <ul className="banner-menu d-flex align-items-center justify-content-md-center pl-0 pb-2 mt-5 pb-md-3 mb-4 mb-md-4">
                    {this.state.facilityCenter?.map((center, id) => {
                      return (
                        <li
                          className="banner-menu-item"
                          style={{ cursor: "pointer" }}
                          key={id}
                        >
                          <a
                            className={
                              center.value === "sober"
                                ? "banner-menu-link active"
                                : "banner-menu-link"
                            }
                            onClick={() => {
                              this.setState({ activeSober: false });
                              this.setState({ activeFacility: center.value });
                              this.props.history.push(`/${center.link}`);
                            }}
                          >
                            {center.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  <h2 className="mb-md-3">TASTE RECOVERY</h2>

                  <div className="form-group position-relative">
                    <LocationSearchInput
                      onCitySelect={this.onCitySelect}
                      fetchStateZip={true}
                      placeholder="Search Sober Living by City"
                    />
                    <span className="search-icon">
                      <img
                        src={require("../../assets/images/search-24px.svg")}
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Top City */}
          {this.props.states.length > 0 && (
            <section className="top-city" id="top7section" tabindex="-1">
              <div className="container">
                <h3 className="main-title">TOP 7 US STATES FOR SOBER LIVING</h3>
                <div className="slider-wrapper">
                  <SliderComponent
                    component={StateCard}
                    items={this.props.states}
                    settings={this.citySliderSettings}
                  />
                </div>
              </div>
            </section>
          )}

          {this.props.livingByGenders && livingByGender}

          {this.props.livingByRecommended.length > 0 && (
            <section
              className="recommended-living"
              id="topRecommendedsection"
              tabindex="-1"
            >
              <div className="container">
                <h3 className="main-title">Recommended Sober Livings</h3>
                <div className="slider-wrapper">
                  <div className="recommended">
                    <div className="row">
                      {this.props.livingByRecommended &&
                        this.props.livingByRecommended.map((item, index) => {
                          return (
                            <div className="col-md-6 col-lg-4" key={index}>
                              <SoberLivingCard
                                item={item}
                                isRecommended="true"
                                hideAmount="true"
                                isHost={this.props.isHost}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
        <SoberLivingheader />
        <Sobercontent />
        <br />
        <Sobercontentfaq />
      </div>
    );
  }

  onCitySelect(data) {
    const { stateLong, cityName, id } = data;
    let city = cityName.replaceAll(", ", "-").replaceAll(" ", "-");

    this.props.history.push({
      pathname: `living/search/${seoUrlText}-${city?.toLowerCase()}`,
      state: data,
      // state: data
    });
  }

  getActiveFacility(data) {
    this.setState({ activeSober: data });
  }

  fetchData() {
    this.props.fetchStates();

    checkHasNavigationPermission()
      .then((granted) => {
        if (!granted) {
          setTimeout(() => {
            this.props.fetchLivingByGender({});
            this.props.fetchLivingByRecommended({});
          }, 100);
        }
        getGeoLocation().then((position) => {
          const { latitude, longitude } = position.coords;
          let data = {};
          if (latitude && longitude) {
            data = {
              coordinates: {
                lat: latitude,
                lng: longitude,
              },
            };
          }
          this.props.fetchLivingByGender(data);
          this.props.fetchLivingByRecommended(data);
        });
      })
      .catch(() => {
        this.props.fetchLivingByGender({});
        this.props.fetchLivingByRecommended({});
      });
  }
}

export default connect(
  ({ home, auth }) => ({
    states: home.states,
    isHost: auth.isHost,
    coordinates: home.coordinate,
    livingByGenders: home.livingByGender,
    livingByRecommended: home.livingByRecommended,
    fetchingData:
      home.fetchingLivingByRecommended ||
      home.fetchingStates ||
      home.fetchingLivingByGender,
  }),
  (dispatch) => {
    return {
      ...bindActionCreators({ ...livingAction, ...homeAction }, dispatch),
    };
  }
)(HomePage);
