import React from "react";
import { Helmet } from "react-helmet";

export default function ReactHelmet({ title, description, faqSchema }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={
          window.location.href
            ? window.location.href
            : "https://tasterecovery.com"
        }
      />
      <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      <link rel="icon" href="/public/icon-176.png" />
    </Helmet>
  );
}
