import React from "react";
import FAQSchemacalofornia from "./FAQSchemacalofornia";

const Californiaheader = () => {
  return (
    <div className="container">
      <FAQSchemacalofornia />
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          <h1 className="faqs mb-0 text-center">Sober Living in California</h1>
          <p>
            If you or a loved one is struggling with substance abuse or
            addiction, there is good news. Your search for sober living in
            California is over.
            <br /> Find sober living in California with confidence here.
            Patients gain access to the lifestyle and care they need as they
            undertake their road to recovery.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Californiaheader;
