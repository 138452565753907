import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterIconOrange from "../../assets/images/filter-new-orange.svg";
import searchIcon from "../../assets/images/search-24px-dark.svg";
import { useHistory } from "react-router-dom";
import noresultIcon from "../../assets/images/no-results.svg";
import { homeAction } from "../../actions";
import pinImg from "../../assets/images/ic_pin_small.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import personalAmenities from "../../assets/images/Personal Amenities.svg";
import locationImage from "../../assets/images/location-new.svg";
import locationDisableImage from "../../assets/images/location-disable.svg";
import { checkHasNavigationPermission } from "../../utils";
import locationModalImage from "../../assets/images/with-location.png";
import locationDisableModalImage from "../../assets/images/without-location.png";
const FacilityHeader = ({
  setStateName,
  perPage,
  getsCords,
  setIsSearchModelOpen,
  getsModelStatus,
  activeFacility,
  setActiveFacility,
  setOpenFilterModal,
  setSearchInput,
  searchInput,
  searchInputtext,
  setSearchInputtext,
  selectSearchOption,
  setSelectSearchOption,
}) => {
  const dispatch = useDispatch();
  const facilityListFromState = useSelector(
    (state) => state?.home?.facilityList
  );
  const filteredFacilityList = useSelector(
    (state) => state?.home?.filterFacilityList
  );

  const location = useLocation();

  const debounceRef = useRef(null);

  const facilityCenter = [
    { value: "detox", label: "Detox", link: "detox" },
    { value: "residential", label: "Residential", link: "" },
    { value: "php", label: "PHP", link: "php" },
    { value: "iop", label: "IOP", link: "iop" },
    { value: "sober", label: "Sober Living", link: "soberliving" },
  ];
  const [searchModel, setSearchModel] = useState(false);
  const [activeLocIndex, setActiveLocIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [coordinates, setCoordinates] = useState({});
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const history = useHistory();
  const modalRef = useRef(null);
  const [grantedPermission, setGrantedPermission] = useState(false);
  const [open, setopen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveFacility("residential");
    } else {
      setActiveFacility(location?.pathname?.replace("/", ""));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInputtext(val);
    debounceFetchAutocompleteResults(val);
    if (e.target.value == "") {
      getsCords({});
      setSearchInput("");
    }
  };
  useEffect(() => {
    if (searchInputtext.length == 0) {
      getsModelStatus(false);
    } else {
      getsModelStatus(true);
    }
  }, [searchInputtext]);

  const handleSearchOnKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(homeAction.setSearchInputField(searchInput));
      getsCords({});
      setSearchModel(false);
      getsModelStatus(false);
    }
  };
  useEffect(() => {
    return () => {
      setAutocompleteResults([]);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (facilityListFromState?.total > 0) {
        dispatch(
          homeAction.fetchFacilityListForFilter({
            perPage: facilityListFromState?.total,
            searchbar: searchInputtext,
            facilityType:
              location?.pathname == "/"
                ? "residential"
                : location?.pathname?.replace("/", ""),
          })
        );
      }
    }, 300);
  }, [facilityListFromState?.total, location?.pathname, searchInputtext]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSearchModel(false);
        // setSearchInputtext("");
      }
    };

    if (searchModel) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchModel]);

  const debounceFetchAutocompleteResults = (input) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      fetchAutocompleteResults(input);
    }, 200);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchInput(searchInputtext);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchInputtext]);

  const handlePlaceSelected = async (place) => {
    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const data = {
        coordinates: {
          lat: Number(latitude),
          lng: longitude,
        },
      };
      setCoordinates(data.coordinates);
      getsCords(data.coordinates);
      setStateName(
        place?.address_components?.find((item) =>
          item?.types?.includes("administrative_area_level_1")
        ).long_name
      );
      await dispatch(homeAction.setSearchCoodinates(data.coordinates));

      await dispatch(homeAction.setSearchInputField(place.formatted_address));
    } else {
      dispatch(homeAction.setSearchCoodinates({}));
      console.error("No geometry available for the selected place.");
    }
    setIsSearchModelOpen(false);
  };

  const fetchAutocompleteResults = (input) => {
    if (!window.google || !input) return;

    const service = new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions({ input }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const filteredData = predictions.filter(
          (item) =>
            item.types.includes("administrative_area_level_1") ||
            item.types.includes("geocode") ||
            item.types.includes("locality")
        );

        setAutocompleteResults(filteredData);
      } else {
        setAutocompleteResults([]);
      }
    });
  };

  const handleSuggestionClick = (description, placeId) => {
    // setIsSearchModelOpen(false)

    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    setSearchModel(false);
    setSearchInputtext(description);
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        handlePlaceSelected(place);
      }
    });
  };

  const handleMouseOverLocation = (result, index) => {
    setActiveLocIndex(index);
  };

  const handleMouseOverLocationFacility = (index) => {
    setActiveIndex(index);
  };

  const data = {
    facilityType: activeFacility,
    searchBar: searchInputtext,
    perPage: 9,
    ...(Object.keys(coordinates).length > 0 && { coordinates }),
  };

  useEffect(() => {
    // const filtered = filteredFacilityList?.facilities?.filter((facility) =>
    //   facility.name.toLowerCase().includes(searchInputtext.toLowerCase())
    // );
    const filtered = filteredFacilityList?.facilities?.filter((facility) =>
      facility.name.toLowerCase().includes(searchInputtext.toLowerCase())
    );
    setFilteredFacilities(filtered);
  }, [searchInputtext, filteredFacilityList]);

  useEffect(() => {
    getlocationStatus();
  }, [grantedPermission]);

  const getlocationStatus = async () => {
    const granted = await checkHasNavigationPermission();
    setGrantedPermission(granted);
  };

  return (
    <section className="facility-list-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="banner-content">
              <h2 className="d-flex d-md-none align-items-center justify-content-between">
                TASTE RECOVERY
                <div className="location-filter d-flex align-items-center">
                  <button
                    type="submit"
                    onClick={() => setopen(true)}
                    className="btn btn-location d-flex align-items-center justify-content-center"
                  >
                    <img
                      src={
                        grantedPermission ? locationImage : locationDisableImage
                      }
                      className="location-icon"
                      alt="Location"
                    />
                    {/* If location disabled use below image */}
                    {/* <img src={locationDisableImage} className="location-icon" alt="Location" /> */}
                  </button>

                  <button
                    type="submit"
                    className="btn btn-filter d-flex align-items-center justify-content-center"
                    onClick={() => setOpenFilterModal(true)}
                  >
                    <img src={filterIconOrange} className="img-fluid" alt="" />
                    Filters
                  </button>
                </div>
              </h2>
              <ul className="banner-menu d-flex align-items-center justify-content-md-center pl-0 pb-2 mt-5 pb-md-3 mb-4 mb-md-4">
                {facilityCenter?.map((center) => (
                  <li
                    key={center.value}
                    className="banner-menu-item"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      onClick={(e) => {
                        setActiveFacility(center.value);
                        // setSearchInputtext("");
                        history.push(`/${center.link}`);
                      }}
                      className={
                        location?.pathname?.replace("/", "") === center.value ||
                        (location?.pathname === "/" &&
                          center.value == "residential")
                          ? "banner-menu-link active"
                          : "banner-menu-link"
                      }
                    >
                      {center.label}
                    </a>
                  </li>
                ))}
              </ul>
              <form ref={modalRef} className="search-wrapper w-100">
                <div className="form-group position-relative mb-0">
                  <input
                    className="form-control mb-0"
                    placeholder="Search by City, State or Facility Name"
                    value={searchInputtext}
                    onClick={() => setSearchModel(true)}
                    onChange={(e) => handleSearch(e)}
                    onKeyDown={handleSearchOnKeyDown}
                  />
                  <span className="search-icon">
                    <img src={searchIcon} className="img-fluid" alt="" />
                  </span>
                </div>
                {searchModel && searchInputtext?.length > 0 && (
                  <div className="search-modal">
                    <div className="search-result-wrapper">
                      {autocompleteResults.length > 0 && (
                        <div className="search-result pb-0">
                          <h3>Locations</h3>
                          <ul className="search-result-list d-flex flex-column">
                            {autocompleteResults.length > 0 &&
                              autocompleteResults.map((result, index) => (
                                <li
                                  key={index}
                                  className={
                                    activeLocIndex === index
                                      ? "active d-flex align-items-center"
                                      : "d-flex align-items-center"
                                  }
                                  onMouseOver={() =>
                                    handleMouseOverLocation(result, index)
                                  }
                                  onClick={() =>
                                    handleSuggestionClick(
                                      result.description,
                                      result.place_id
                                    )
                                  }
                                >
                                  <img
                                    src={pinImg}
                                    alt="Location"
                                    className="img-fluid location-img"
                                  />
                                  <p className="mb-0">{result.description}</p>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {/* {filteredFacilities?.length > 0 && (
                        <div className="search-result pb-0">
                          <h3>Facilities</h3>
                          <ul className="search-result-list d-flex flex-column">
                            {filteredFacilities?.length > 0 &&
                              filteredFacilities
                                ?.map((facility, index) => (
                                  <li
                                    key={facility.id}
                                    className={
                                      activeIndex === index
                                        ? "active d-flex align-items-center"
                                        : "d-flex align-items-center"
                                    }
                                    onMouseOver={() =>
                                      handleMouseOverLocationFacility(index)
                                    }
                                    onClick={() => {
                                      history.push(
                                        `/home/centers/view/${facility?.id}`
                                      );
                                    }}
                                  >
                                    <img
                                      src={personalAmenities}
                                      alt="Facility"
                                      className="img-fluid location-img"
                                    />
                                    <p className="mb-0">
                                      {facility?.name
                                        ?.split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                      {" - "}
                                      {facility?.city}
                                      {", "}
                                      {facility?.state}
                                    </p>
                                  </li>
                                ))
                                .splice(0, 5)}
                          </ul>
                        </div>
                      )} */}

                      {filteredFacilities?.length === 0 &&
                        autocompleteResults.length === 0 &&
                        searchInputtext?.length > 0 && (
                          <div className="search-result">
                            <ul className="search-result-list d-flex flex-column">
                              {autocompleteResults.length > 0 &&
                                autocompleteResults.map((result, index) => (
                                  <li
                                    key={index}
                                    className={
                                      activeLocIndex === index
                                        ? "active d-flex align-items-center"
                                        : "d-flex align-items-center"
                                    }
                                    onMouseOver={() =>
                                      handleMouseOverLocation(result, index)
                                    }
                                    onClick={() =>
                                      handleSuggestionClick(
                                        result.description,
                                        result.place_id
                                      )
                                    }
                                  >
                                    <img
                                      src={pinImg}
                                      alt="Location"
                                      className="img-fluid location-img"
                                    />
                                    <p className="mb-0">{result.description}</p>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      {filteredFacilities?.length > 0 && (
                        <div className="search-result pb-0">
                          <h3>Facilities</h3>
                          <ul className="search-result-list d-flex flex-column">
                            {filteredFacilities?.length > 0 &&
                              filteredFacilities
                                ?.map((facility, index) => (
                                  <li
                                    key={facility.id}
                                    className={
                                      activeIndex === index
                                        ? "active d-flex align-items-center"
                                        : "d-flex align-items-center"
                                    }
                                    onMouseOver={() =>
                                      handleMouseOverLocationFacility(index)
                                    }
                                    onClick={() => {
                                      history.push(
                                        `/home/centers/view/${facility?.id}`
                                      );
                                    }}
                                  >
                                    <img
                                      src={personalAmenities}
                                      alt="Facility"
                                      className="img-fluid location-img"
                                    />
                                    <p className="mb-0">
                                      {facility?.name
                                        ?.split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                      {" - "}
                                      {facility?.city}
                                      {", "}
                                      {facility?.state}
                                    </p>
                                  </li>
                                ))
                                .splice(0, 5)}
                          </ul>
                        </div>
                      )}

                      {filteredFacilities?.length === 0 &&
                        autocompleteResults.length === 0 &&
                        searchInputtext?.length > 0 && (
                          <div className="search-result">
                            <ul className="search-result-list d-flex flex-column">
                              <div className="no-result-found">
                                <img
                                  src={noresultIcon}
                                  className="img-fluid"
                                  alt="search"
                                />
                                <h4>No results found</h4>
                                <p>
                                  Enter search term for searching locations or
                                  facilities
                                </p>
                              </div>
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <>
        {open && (
          <div
            className="modal upgrade-plan cancel-subscription-modal fade show"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="upgrade-plan-content">
                  <h3>
                    Location Access {grantedPermission ? "Enabled" : "Disabled"}{" "}
                  </h3>
                  <span className="loading-msg">
                    Location services are currently{" "}
                    {grantedPermission ? "enabled" : "disabled"}. You can manage
                    location settings in the site settings.
                  </span>
                  <img
                    src={
                      grantedPermission
                        ? locationModalImage
                        : locationDisableModalImage
                    }
                    className="modal-image"
                    alt="Location"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="modal-btn"
                    onClick={() => setopen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="modal-btn confirm-btn"
                    onClick={() => setopen(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </section>
  );
};

export default FacilityHeader;
