import { httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const residentialActionType = {
  Residential_Adding: "[Adding_Residential] Action",
  Residential_Add: "[Add_Residential] Action",
  Residential_Editing: "[Editing_Residential] Action",
  Residential_Edited: "[Edited_Residential] Action",
  fetch_Residential_ById_ActionType: "[GetById_Residential] Action",
  fetch_Residential_ById_Success: "[GetById_Residential_Sucess] Action",
  fetch_Residential_ById_Failure: "[GetById_Residential_Failure] Action",

  set_Res_data_ActionType: "[set_Res_data] Action",
  set_Res_data_Success: "[set_Res_data_Sucess] Action",
  set_Res_data_Failure: "[set_Res_data_Failure] Action",

  SubscriptionPlan_Fetching: "[ Fetching_ResidentialPlan] Action",
  SubscriptionPlan_Fetched: "[ Fetched_ResidentialPlan] Action",
  ResidentialBasicPlan_Set: "[Set_ResidentialBasicPlan] Action",
  ResidentialProPlan_Set: "[Set_ResidentialProPlan] Action",
  ResidentialFacilityType_Set: "[Set_ResidentialFacilityType] Action",
  RESET_DATA: "[RESET_DATA] Action",
  Residential_Sub: "[sub_Residential] Action",
};

const onAddResidential = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: residentialActionType.Residential_Adding });

      const res = await httpClient.post(SERVER_APIS.Add_Residential, data);

      dispatch({
        type: residentialActionType.Residential_Add,
        payload: { residential: res?.data },
      });

      return res;
    } catch (error) {
      console.error("Error occurred:", error);

      dispatch({
        type: residentialActionType.Residential_Add,
        payload: { residential: {} },
      });

      throw error;
    }
  };
};

// set data

const onAddRes = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: residentialActionType.set_Res_data_ActionType });

      dispatch({
        type: residentialActionType.set_Res_data_Success,
        payload: { setData: data },
      });

      return;
    } catch (error) {
      console.error("Error occurred:", error);

      dispatch({
        type: residentialActionType.set_Res_data_Failure,
        payload: { setData: {} },
      });

      throw error;
    }
  };
};

const fetchResidencialDataById = (id) => {
  return (dispatch) => {
    dispatch({ type: residentialActionType.fetch_Residential_ById_ActionType });
    return httpClient
      .get(`${SERVER_APIS.Residential}/${id}`)
      .then((res) => {
        dispatch({
          type: residentialActionType.fetch_Residential_ById_Success,
          payload: { residetial: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch({
          type: residentialActionType.fetch_Residential_ById_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};

const onEditResidential = (id, data) => {
  return (dispatch) => {
    dispatch({ type: residentialActionType.Residential_Editing });
    return httpClient
      .patch(`${SERVER_APIS.Add_Residential}/${id}`, data)
      .then((res) => {
        dispatch({
          type: residentialActionType.Residential_Edited,
          payload: res.data,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: residentialActionType.Residential_Edited,
        });
        return Promise.reject(data);
      });
  };
};

//subscription part
const onFetchResidentialPlan = () => {
  return (dispatch) => {
    dispatch({ type: residentialActionType.SubscriptionPlan_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Residential_Plan}`)
      .then((response) => {
        dispatch({
          type: residentialActionType.SubscriptionPlan_Fetched,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: residentialActionType.SubscriptionPlan_Fetched,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const setResidentialBasicPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: residentialActionType.ResidentialBasicPlan_Set,

      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setResidentialProPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: residentialActionType.ResidentialProPlan_Set,
      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setResidentialFacilityType = (data) => {
  return (dispatch) => {
    dispatch({
      type: residentialActionType.ResidentialFacilityType_Set,
      payload: {
        facilitytype: data?.facilityType,
        id: data?.id,
        name: data?.name,
      },
    });
  };
};

const isChangeTrue = (data) => {
  return (dispatch) => {
    dispatch({
      type: residentialActionType.Residential_Sub,
      payload: data,
    });
  };
};
const resetresData = () => ({
  type: residentialActionType?.RESET_DATA,
});
export const residentialAction = {
  addResidential: onAddResidential,
  getResidencialById: fetchResidencialDataById,
  editResidential: onEditResidential,
  onAddRes: onAddRes,
  getResidentialSubscriptionPlan: onFetchResidentialPlan,
  setResidentialProPlan,
  setResidentialBasicPlan,
  setResidentialFacilityType,
  isChangeTrue,
  resetresData
};
